<template>
  <div class="usercenter">
    <div class="content">
      <div class="editBtn">
        <!-- <el-button size="small" @click="handleEdit(1)">修改信息</el-button> -->
        <el-button size="small" @click="handleEdit(2)">修改密码</el-button>
      </div>
      <span class="title">个人信息</span>
      <div class="info">
        <div class="item">姓名：{{name}}</div>
        <div class="item">手机号：{{phone}}</div>
      </div>
    </div>
    <el-dialog
      :title="type=='1'?'修改信息':'修改密码'"
      :visible.sync="dialogVisible"
      custom-class="usercenter-dialog"
      width="400px"
      :close-on-click-modal="false"
      :before-close="handleClose" 
    >
      <el-form ref="form">
        <el-form-item label="姓 名" v-if="type==1">
          <el-input v-model.trim="form.nickname" placeholder="请输入" clearable/>
        </el-form-item>
        <template v-else>
          <el-form-item label="当前密码">
            <el-input v-model.trim="form.oldPassword" placeholder="请输入" clearable show-password/>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input v-model.trim="form.newPassword" placeholder="请输入" clearable show-password/>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input v-model.trim="form.surePassword" placeholder="请输入" clearable show-password/>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo,updateUserInfo } from '@/api/usercenter'
export default {
  name: "usercenter",
  data() {
    return {
      name:'',
      phone:'',
      password:'',
      dialogVisible:false,
      form:{
        nickname:'',
        phone:'',
        oldPassword:'',
        newPassword:'',
        surePassword:'',
      },
      type:1
    };
  },
  activated(){
    this.getUserDetails()
  },
  methods:{
    handleClose(){
      this.dialogVisible = false
      Object.assign(this.form,this.$options.data().form)
    },
    handleEdit(type){
      this.type = type
      this.dialogVisible = true
    },
    //获取个人信息
    getUserDetails(){
      getUserInfo().then(res=>{
        if(res.code == 200){
          this.name = res.data.name
          this.phone = res.data.phone
        }else{
          this.$message.error(res.msg || '接口出错')
        }
      })
    },
    //修改个人信息
    handleSure(){
      let params = {}
      if(this.type === 1){
        if(!this.form.nickname){
          return this.$message.error('请输入姓名')
        }
        params.nickname = this.form.nickname
      }else{
        let { surePassword, oldPassword, newPassword} = this.form
        if(!oldPassword || !newPassword ||!surePassword){
          return this.$message.error('请输入完整信息')
        }
        if(newPassword!== surePassword){
          return this.$message.error('两次输入的密码不一致')
        }
        params.nickname = this.nickname
        params.oldPassword = this.form.oldPassword
        params.newPassword = this.form.newPassword
        params.surePassword = this.form.surePassword
      }
      updateUserInfo(params).then(res=>{
        if(res.code == 200){
          this.dialogVisible = false
          if(this.type == 1){
            this.$message.success('修改成功')
            this.getUserDetails()
          }else{
            this.$message.success('修改成功,请重新登录')
            localStorage.removeItem('taskmanuserToken')
            this.$router.push({path:'/login'})
          }
        }else{
          this.$message.error(res.msg || '接口出错')
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/element-reset.scss';
.usercenter{
  padding:10px 20px;
  position: relative;
  height: calc(100vh - 140px);
  text-align: left;
  color: #E8E8E8;
  .content{
    background: #262626;
    border-radius: 8px;
    position: relative;
    padding: 23px 39px;
    .editBtn{
      position: absolute;
      top:10px;
      right: 20px;
    }
    .title{
      font-size: 20px;
    }
    .info{
      display: flex;
      margin: 25px 0px 20px 0px;
      font-size: 18px;
      .item{
        width: 40%;
      }
    }
  }
}
::v-deep .usercenter-dialog{
  .el-dialog__title{
    font-size: 20px;
  }
  .el-form-item__label{
    font-size: 16px;
  }
}
</style>
