// 个人中心
import { get,put } from "../utils/http";

//获取个人信息
export function getUserInfo() {
  return get('/user/info')
}

//修改个人信息
export function updateUserInfo(data) {
  return put('/user/by-self',data)
}
